<template>
  <div>
    <v-card flat>
      <apexchart :options="options" :series="series"></apexchart>
      <div class="text-center font-weight-medium pb-5">
        Biến đổi giá nhập 30 ngày qua
      </div>
    </v-card>
    <v-card class="mt-5" flat>
      <v-card-title class="align-center pb-2">
        <div class="mr-4">Lịch sử thay đổi</div>
        <v-radio-group class="mt-0 pt-0" hide-details row>
          <v-radio label="Giá nhập"></v-radio>
          <v-radio label="Giá bán"></v-radio>
        </v-radio-group>
      </v-card-title>
      <v-data-table
        class="datatable px-3 py-2"
        calculate-widths
        disable-pagination
        no-data-text="Không có serial nào"
        no-results-text="Không tìm thấy kết quả phù hợp"
        :headers="headers"
        hide-default-footer
        hide-default-header
        :loading="false"
        loading-text="Đang tải dữ liệu"
        :items="items"
        item-key="id"
      >
        <template v-slot:[`item.price`]="{ item }">
          <v-hover v-slot:default="{ hover }">
            <div
              class="view-label"
              :class="{ 'text-decoration-underline font-weight-bold': hover }"
            >
              {{ item.price | formatCurrency }}
            </div>
          </v-hover>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Ngày",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Mã phiếu nhập",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Giá nhập",
          align: "center",
          sortable: false,
          value: "price"
        }
      ],
      items: [
        { created_at: "2020-10-01", code: "PN0294", price: 17500000 },
        { created_at: "2020-10-05", code: "PN0297", price: 17500000 },
        { created_at: "2020-10-11", code: "PN0305", price: 17500000 }
      ],
      options: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false
          },
          height: "400px"
        },
        xaxis: {
          categories: [
            "22/09/2020",
            "28/09/2020",
            "30/09/2020",
            "02/10/2020",
            "10/10/2020",
            "12/10/2020"
          ]
        },
        colors: ["#FF9800", "#66DA26"]
      },
      series: [
        {
          name: "Giá nhập",
          data: [22600000, 17900000, 24500000, 29500000, 34300000, 38660000]
        },
        {
          name: "Giá bán",
          data: [32600000, 27900000, 34500000, 33500000, 38300000, 40670000]
        }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  }
};
</script>

<template>
  <div class="d_flex">
    <v-overlay
      v-show="
        productOptionStatusRequest.value === 'loading-getProductOptionInfo' ||
          productOptionStatusRequest.value ===
            'loading-getProductOptionSerials' ||
          productOptionStatusRequest.value ===
            'loading-getProductOptionTransactionHistory'
      "
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :brands-filter="brandsFilter"
        :status="status"
        :not-sold-warehouse-status-filter="notSoldWarehouseStatusFilter"
        :ordered-status-filter="orderedStatusFilter"
        :price-filter="priceFilter"
        :product-source-filter="productSourceFilter"
        :product-status-filter="productStatusFilter"
        :product-types-filter="productTypesFilter"
        :sale-status-filter="saleStatusFilter"
        :site-filter="siteFilter"
        :store-status-filter="storeStatusFilter"
        :waiting-ordered-status-filter="waitingOrderedStatusFilter"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :search-options="searchOptions"
        :search-type="searchType"
        :selected-product-options="selectedProductOptions"
        :selected-show-filter-id="itemPerPage"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedProductOptions="selectedProductOptions = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :selected-product-options="selectedProductOptions"
          :sort="sort"
          @updateSelectedProductOptions="selectedProductOptions = $event"
          @updateSort="updateSort($event)"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal front display -->
    <modal-front-display></modal-front-display>
    <!-- End: Modal front display -->
    <!-- Start: Modal product option detail -->
    <modal-product-option-detail></modal-product-option-detail>
    <!-- End: Modal product option detail -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import ModalFrontDisplay from "./components/modal/ModalFrontDisplay";
import ModalProductOptionDetail from "./components/modal/ModalProductOptionDetail/index";
import TopAction from "./components/TopAction";
import { exportExcelFile, updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    ModalFrontDisplay,
    ModalProductOptionDetail,
    TopAction
  },

  data() {
    return {
      brandsFilter: null,
      currentPage: 1,
      itemPerPage: 50,
      notSoldWarehouseStatusFilter: [],
      orderedStatusFilter: null,
      priceFilter: {
        from: null,
        to: null
      },
      productSourceFilter: null,
      productStatusFilter: [],
      productTypesFilter: null,
      saleStatusFilter: 1,
      storeStatusFilter: [],
      selectedProductOptions: [],
      searchKey: null,
      searchType: 0,
      searchOptions: [
        { type: "searchProduct", label: "Theo mã, tên sản phẩm" },
        { type: "searchOption", label: "Theo mã SKU và MPN" }
      ],
      siteFilter: "ALL",
      sort: {
        type: null,
        field: null
      },
      isLoading: false,
      waitingOrderedStatusFilter: null,
      status: "ALL"
    };
  },

  watch: {
    "$route.query"(val) {
      this.getProductOptions(val);
    }
  },

  computed: {
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },

  async created() {
    const route = this.$route;

    await this.getProductOptions(route.query);
  },

  methods: {
    async exportReportFile() {
      await exportExcelFile(this, {
        storeName: "PRODUCT_OPTION",
        payload: {
          productTypes: this.productTypesFilter,
          brandIds: this.brandsFilter,
          productSource: this.productSourceFilter,
          productStatus: this.productStatusFilter,
          storeStatus: this.storeStatusFilter,
          status: this.status,
          saleStatus: this.saleStatusFilter,
          search: {
            type: this.searchType,
            key: this.searchKey
          }
        },
        fileName: "bao-cao-ton-kho"
      });
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async getProductOptions(query) {
      if (query.page) {
        // Set filtered brands
        this.brandsFilter =
          typeof query.brand_id === "string"
            ? [parseInt(query.brand_id)]
            : typeof query.brand_id === "object"
            ? query.brand_id.map(item => parseInt(item))
            : null;
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set front display
        this.status = query.status || "ALL";
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered not sold warehouse status
        this.notSoldWarehouseStatusFilter =
          typeof query.off_biz === "string"
            ? [parseInt(query.off_biz)]
            : typeof query.off_biz === "object"
            ? query.off_biz.map(item => parseInt(item))
            : [];
        // Set ordered status filter
        this.orderedStatusFilter = query.ordered_status
          ? parseInt(query.ordered_status)
          : null;
        // Set price filter
        this.priceFilter =
          query.price_from && query.price_to
            ? { from: query.price_from, to: query.price_to }
            : { from: null, to: null };
        // Set filtered product source
        this.productSourceFilter = query.pr_src
          ? parseInt(query.pr_src)
          : "all";
        // Set filtered product status
        this.productStatusFilter =
          typeof query.pr_status === "string"
            ? [parseInt(query.pr_status)]
            : typeof query.pr_status === "object"
            ? query.pr_status.map(item => parseInt(item))
            : [];
        // Set filtered product types
        this.productTypesFilter =
          typeof query.category_id === "string"
            ? [parseInt(query.category_id)]
            : typeof query.category_id === "object"
            ? query.category_id.map(item => parseInt(item))
            : null;
        // Set filtered sale status
        this.saleStatusFilter = parseInt(query.ttkd);
        // Set filtered store status
        this.storeStatusFilter =
          typeof query.store_status === "string"
            ? [parseInt(query.store_status)]
            : typeof query.store_status === "object"
            ? query.store_status.map(item => parseInt(item))
            : [];
        // Set search key
        this.searchKey = query.search || null;
        // Set search type
        this.searchType = query.search ? parseInt(query.search_type) : 0;

        this.siteFilter = query.site || "ALL";

        // Set sort field
        this.sort.field = query.sort_by || null;
        this.sort.type = query.sort_type ? parseInt(query.sort_type) : null;
        // Set waiting ordered status filter
        this.waitingOrderedStatusFilter = query.waiting_ordered_status
          ? parseInt(query.waiting_ordered_status)
          : null;

        // Get products
        await this.$store.dispatch("PRODUCT_OPTION/getProductOptions", {
          filter: {
            category_id: this.productTypesFilter,
            brand_id: this.brandsFilter,
            nguon_hang:
              this.productSourceFilter && this.productSourceFilter !== "all"
                ? [this.productSourceFilter]
                : null,
            tinh_trang: this.productStatusFilter,
            tinh_trang_kho: this.storeStatusFilter,
            fromPrice: this.priceFilter
              ? parseInt(this.priceFilter.from)
              : null,
            toPrice: this.priceFilter ? parseInt(this.priceFilter.to) : null,
            site: this.siteFilter,
            status:
              this.status !== "ALL" && this.status !== null
                ? [this.status]
                : null,
            ttkd: [this.saleStatusFilter],
            off_biz: this.notSoldWarehouseStatusFilter,
            ordered: this.orderedStatusFilter
              ? this.orderedStatusFilter
              : undefined,
            waiting_ordered: this.waitingOrderedStatusFilter
              ? this.waitingOrderedStatusFilter
              : undefined
          },
          searchProduct:
            this.searchOptions[this.searchType].type === "searchProduct"
              ? this.searchKey
              : null,
          searchOption:
            this.searchOptions[this.searchType].type === "searchOption"
              ? this.searchKey
              : null,
          sort: {
            type: this.sort.type,
            field: this.sort.field
          },
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      await this.$router.push({
        name: "goods_product-options",
        query: {
          category_id:
            this.productTypesFilter && this.productTypesFilter.length > 0
              ? this.productTypesFilter
              : undefined,
          brand_id:
            this.brandsFilter && this.brandsFilter.length > 0
              ? this.brandsFilter
              : undefined,
          pr_src:
            this.productSourceFilter && this.productSourceFilter !== "all"
              ? this.productSourceFilter
              : undefined,
          pr_status:
            this.productStatusFilter && this.productStatusFilter.length > 0
              ? this.productStatusFilter
              : undefined,
          store_status:
            this.storeStatusFilter && this.storeStatusFilter.length > 0
              ? this.storeStatusFilter
              : undefined,
          ordered_status: this.orderedStatusFilter
            ? this.orderedStatusFilter
            : undefined,
          waiting_ordered_status: this.waitingOrderedStatusFilter
            ? this.waitingOrderedStatusFilter
            : undefined,
          price_from:
            this.priceFilter && this.priceFilter.from
              ? this.priceFilter.from
              : undefined,
          price_to:
            this.priceFilter && this.priceFilter.to
              ? this.priceFilter.to
              : undefined,
          site: this.siteFilter,
          status:
            this.status !== null && this.status !== "ALL"
              ? this.status
              : undefined,
          ttkd: this.saleStatusFilter,
          off_biz:
            this.notSoldWarehouseStatusFilter &&
            this.notSoldWarehouseStatusFilter.length > 0
              ? this.notSoldWarehouseStatusFilter
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,
          sort_by: this.sort && this.sort.field ? this.sort.field : undefined,
          sort_type:
            this.sort && this.sort.field && this.sort.type
              ? this.sort.type
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSort(val) {
      this.sort = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>

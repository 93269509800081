<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          v-model="searchKey"
          :default-option="searchType"
          :options="searchOptions"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="openModalProduct()"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          @click="$refs.file.click()"
        >
          <v-icon left>mdi-file-upload-outline</v-icon>
          Nhập file
        </v-btn>
        <v-btn
          :loading="
            productOptionStatusRequest.value === 'loading-exportReportFile'
          "
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          @click="$emit('exportReportFile')"
        >
          <v-icon left>mdi-file-download-outline</v-icon>
          Xuất file
        </v-btn>
        <input
          ref="file"
          accept=".xlsx, .xls"
          hidden
          type="file"
          @change="uploadExcelFile()"
        />
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          btn-class="white rounded-lg"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedProductOptions.length > 0"
          :active-item="false"
          :items="actionList"
          btn-class="white rounded-lg ml-3"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div v-if="selectedProductOptions.length > 0" class="ml-3">
          Đã chọn <strong>{{ this.selectedProductOptions.length }}</strong> sản
          phẩm
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
          color="primary"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
    },
    selectedProductOptions: {
      type: Array,
      required: true,
    },
    selectedShowFilterId: {
      type: Number,
    },
    searchKey: {
      type: String,
    },
    searchType: {
      type: Number,
    },
    searchOptions: {
      type: Array,
    },
  },
  data() {
    return {
      actionList: [
        { id: "delete", text: "Xóa" },
        { id: "group", text: "Nhóm sản phẩm" },
        { id: "hide-show", text: "Ẩn/Hiện trên website" },
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" },
      ],
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      },
    },
    paginationInfo() {
      return this.$store.getters["PRODUCT_OPTION/paginationInfo"];
    },
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },
  },
  methods: {
    openModalProduct() {
      this.$modal.show({
        name: "modal-product",
      });
    },

    selectAction(val) {
      if (val.id === "hide-show") {
        this.$modal.show({
          name: "modal-product-options-change-front-display",
          payload: {
            selectedLength: this.selectedProductOptions.length,
            selectedItems: this.selectedProductOptions,
          },
        });
      }
      if (val.id === "delete") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span>Xóa <strong>"${this.selectedProductOptions.length}"</strong> sản phẩm đã chọn</span>`,
            message:
              "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
            confirmBtn: {
              color: "red accent-2",
              text: "Xóa",
              onClickHandler: async () => {
                const routeQuery = this.$route.query;

                await this.$store.dispatch(
                  "PRODUCT_OPTION/deleteProductOptions",
                  {
                    data: {
                      ids: this.selectedProductOptions.map((item) => item.id),
                    },
                    routeQuery: {
                      filters: {
                        category_id:
                          typeof routeQuery.category_id === "string"
                            ? [parseInt(routeQuery.category_id)]
                            : typeof routeQuery.category_id === "object"
                            ? routeQuery.category_id.map((item) =>
                                parseInt(item)
                              )
                            : null,
                        brand_id:
                          typeof routeQuery.brand_id === "string"
                            ? [parseInt(routeQuery.brand_id)]
                            : typeof routeQuery.brand_id === "object"
                            ? routeQuery.brand_id.map((item) => parseInt(item))
                            : null,
                        nguon_hang: routeQuery.pr_src
                          ? [parseInt(routeQuery.pr_src)]
                          : null,
                        tinh_trang:
                          typeof routeQuery.pr_status === "string"
                            ? [parseInt(routeQuery.pr_status)]
                            : typeof routeQuery.pr_status === "object"
                            ? routeQuery.pr_status.map((item) => parseInt(item))
                            : [],
                        tinh_trang_kho: routeQuery.store_status
                          ? [parseInt(routeQuery.store_status)]
                          : null,
                        listing_display_thinkpro:
                          Number(routeQuery.listing_display_thinkpro) || 1,
                        listing_display_goodspace:
                          Number(routeQuery.listing_display_goodspace) || 1,
                      },
                      sort: {
                        type: routeQuery.sort_type
                          ? parseInt(routeQuery.sort_type)
                          : null,
                        field: routeQuery.sort_by ? routeQuery.sort_by : null,
                      },
                      search: routeQuery.search || null,
                      per_page: parseInt(routeQuery.limit),
                      cur_page: parseInt(routeQuery.page),
                    },
                  }
                );
                if (
                  this.productOptionStatusRequest.value ===
                  "success-deleteProductOptions"
                ) {
                  this.$emit("updateSelectedProductOptions", []);
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Đã xóa thành công",
                    },
                  });
                }
              },
            },
          },
        });
      }
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();
      const routeQuery = this.$route.query;

      Array.from(excelFile).forEach((f) => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("PRODUCT_OPTION/importExcel", {
        formData: formData,
        routeQuery: {
          filters: {
            category_id:
              typeof routeQuery.category_id === "string"
                ? [parseInt(routeQuery.category_id)]
                : typeof routeQuery.category_id === "object"
                ? routeQuery.category_id.map((item) => parseInt(item))
                : null,
            brand_id:
              typeof routeQuery.brand_id === "string"
                ? [parseInt(routeQuery.brand_id)]
                : typeof routeQuery.brand_id === "object"
                ? routeQuery.brand_id.map((item) => parseInt(item))
                : null,
            nguon_hang: routeQuery.pr_src
              ? [parseInt(routeQuery.pr_src)]
              : null,
            tinh_trang:
              typeof routeQuery.pr_status === "string"
                ? [parseInt(routeQuery.pr_status)]
                : typeof routeQuery.pr_status === "object"
                ? routeQuery.pr_status.map((item) => parseInt(item))
                : [],
            tinh_trang_kho: routeQuery.store_status
              ? [parseInt(routeQuery.store_status)]
              : null,
            listing_display_thinkpro:
              Number(routeQuery.listing_display_thinkpro) ?? 1,
            listing_display_goodspace:
              Number(routeQuery.listing_display_goodspace) ?? 1,
          },
          sort: {
            type: routeQuery.sort_type ? parseInt(routeQuery.sort_type) : null,
            field: routeQuery.sort_by ? routeQuery.sort_by : null,
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page),
        },
      });
      // Check request status
      if (this.productOptionStatusRequest.value === "success-importExcel") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file sản phẩm",
          },
        });
      }
      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },

    exportExcel() {
      this.$emit("exportExcel");
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>

<template>
  <v-container class="pa-0" fluid max-width="960px" width="60%">
    <!-- Start: Status statistics -->
    <v-row class="mx-8 px-8">
      <v-col class="pa-0" cols="4">
        <v-card class="white fill-height mx-2 pb-3" flat>
          <div
            class="text-h6 orange--text lighten-4 font-weight-bold text-center pa-3 pb-0"
          >
            Tồn kho kinh doanh
          </div>
          <v-card-text
            class="text-h5 orange--text font-weight-bold text-center px-3 py-2"
          >
            {{ productOptionInfo.ton_kho_kinh_doanh }}
          </v-card-text>
          <div
            class="d-lg-flex justify-lg-space-between px-lg-5 text-center mt-2"
          >
            <div class="mb-1">
              Đã đặt: <span class="font-weight-bold">{{ "N/A" }}</span>
            </div>
            <div>
              Sẵn bán:
              <span class="font-weight-bold">{{
                productOptionInfo.san_ban
              }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="4">
        <v-card class="white fill-height mx-2 pb-3" flat>
          <div
            class="text-h6 deep-purple--text lighten-3 font-weight-bold text-center pa-3 pb-0"
          >
            Đang về
          </div>
          <v-card-text
            class="text-h5 deep-purple--text font-weight-bold text-center px-3 py-2"
          >
            {{ productOptionInfo.dang_ve }}
          </v-card-text>
          <div
            class="d-lg-flex justify-lg-space-between px-lg-5 text-center mt-2"
          >
            <div class="mb-1 mb-lg-0">
              Đã đặt: <span class="font-weight-bold">{{ "N/A" }}</span>
            </div>
            <div>
              Sẵn đặt: <span class="font-weight-bold">{{ "N/A" }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="4">
        <v-card class="green--text lighten-3 fill-height mx-2" flat>
          <div class="text-h6 font-weight-bold text-center pa-3 pb-0">
            Đặt chờ xử lý
          </div>
          <v-card-text
            class="text-h5 green--text font-weight-bold text-center px-3 py-2"
          >
            N/A
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- End: Status statistics -->
    <!-- Start: General info -->
    <v-row class="mt-5 mx-8 px-10">
      <v-card class="px-5 py-4" flat>
        <v-row class="mx-0">
          <div
            v-if="isViewedCreatedPrice"
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Giá nhập gần nhất:</div>
            <div class="item-info--value">
              {{ productOptionInfo.gia_nhap_gan_nhat | formatCurrency }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Số bán 30 ngày qua:</div>
            <div class="item-info--value">
              {{ productOptionInfo.so_luong_ngay_qua }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Doanh thu 30 ngày qua:</div>
            <div class="item-info--value">
              {{ productOptionInfo.doanh_thu_ngay_qua | formatCurrency }}
            </div>
          </div>
        </v-row>

        <v-row class="mx-0 my-4">
          <v-divider></v-divider>
        </v-row>

        <v-row class="mx-0">
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Tên sản phẩm:</div>
            <div class="item-info--value">
              {{ productOptionInfo.product_name }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Mã sản phẩm:</div>
            <div class="item-info--value">
              {{ productOptionInfo.product_code }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>SKU:</div>
            <div class="item-info--value">{{ productOptionInfo.SKU }}</div>
          </div>
          <div
            v-if="isViewedCreatedPrice"
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Giá nhập trung bình:</div>
            <div class="item-info--value">
              {{ productOptionInfo.gia_von_trung_binh | formatCurrency }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Giá bán:</div>
            <div class="item-info--value">
              {{ productOptionInfo.price | formatCurrency }}
            </div>
          </div>
          <div
            class="item-info d_flex align-center justify-space-between w_100 py-1"
          >
            <div>Trạng thái:</div>
            <div class="item-info--value">
              <v-chip
                :color="
                  productOptionInfo.status === 1
                    ? 'green'
                    : productOptionInfo.status === -1
                    ? 'red accent-2'
                    : 'grey'
                "
                class="text-body-1 font-weight-bold"
                outlined
              >
                {{
                  productOptionInfo.status === 1
                    ? "Còn hàng"
                    : productOptionInfo.status === -1
                    ? "Hết hàng"
                    : "N/A"
                }}
              </v-chip>
            </div>
          </div>
        </v-row>
      </v-card>
    </v-row>
    <!-- End: General info -->
    <!-- Start: Third store -->
    <div class="mt-5 mx-8 px-10">
      <v-card flat>
        <div class="font-weight-bold mx-5 pt-4">Kho bên thứ ba</div>
        <v-data-table
          :headers="headers"
          :items="productOptionInfo.third"
          calculate-widths
          class="datatable px-5 pb-2 pt-1"
          disable-pagination
          hide-default-footer
          item-key="id"
          loading-text="Đang tải dữ liệu"
          no-data-text="Không có dữ liệu"
        >
          <template v-slot:[`item.stock_status`]="{ item }">
            <v-chip
              :color="
                item.stock_status === 1
                  ? 'green'
                  : item.stock_status === 2
                  ? 'red accent-2'
                  : 'orange'
              "
              class="font-weight-bold"
              outlined
              small
            >
              {{
                item.stock_status === 1
                  ? "Còn hàng"
                  : item.stock_status === 2
                  ? "Hết hàng"
                  : "Đang về"
              }}
            </v-chip>
          </template>

          <template v-slot:[`item.promotion`]="{ item }">
            {{ item.promotion ? item.promotion : "Không có" }}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <!-- End: Third store -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Khu vực",
          align: "start",
          sortable: false,
          value: "supplier_tinh_thanhpho"
        },
        {
          text: "Nhà cung cấp",
          align: "start",
          sortable: false,
          value: "supplier_code"
        },
        {
          text: "Tình trạng",
          align: "start",
          sortable: false,
          value: "stock_status"
        },
        {
          text: "Khuyến mại",
          align: "start",
          sortable: false,
          value: "promotion"
        },
        {
          text: "Thời gian cập nhật",
          align: "start",
          sortable: false,
          value: "updated_at"
        }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  computed: {
    productOptionInfo() {
      return this.$store.getters["PRODUCT_OPTION/productOptionInfo"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  }
};
</script>

<style lang="scss" scoped>
.item-info {
  font-size: 1rem;

  &--value {
    font-size: 1.125rem;
    font-weight: 500;
  }
}
</style>

<template>
  <tp-modal
    name="modal-product-options-detail"
    width="70%"
    max-width="960px"
    v-if="productOptionInfo"
  >
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        height="56x"
        extension-height="40px"
      >
        <v-toolbar-title class="font-weight-bold">
          {{ productOptionInfo.product_name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <v-tab
              class="font-weight-bold"
              v-for="item in tabItems"
              :key="item.id"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items class="modal-body-scroll" v-model="activeTab">
        <v-tab-item
          class="tab-item grey lighten-3 pa-5"
          v-for="item in tabItems"
          :key="item.id"
        >
          <component ref="tabItems" :is="`tab-${item.id}`"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </tp-modal>
</template>

<script>
import TabInfo from "./components/TabInfo";
import TabPriceFluctuation from "./components/TabPriceFluctuation";
import TabSerials from "./components/TabSerials";
import TabTransactionHistory from "./components/TabTransactionHistory";

export default {
  components: {
    TabInfo,
    TabPriceFluctuation,
    TabSerials,
    TabTransactionHistory
  },
  data() {
    return {
      activeTab: null,
      tabItems: [
        { id: "info", text: "Thông tin" },
        { id: "transaction-history", text: "Lịch sử giao dịch" },
        { id: "price-fluctuation", text: "Biến đổi giá" },
        { id: "serials", text: "Serials" }
      ]
    };
  },
  computed: {
    productOptionInfo() {
      return this.$store.getters["PRODUCT_OPTION/productOptionInfo"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-product-options-detail"
      });
      this.activeTab = null;
      // Reset tab item data
      this.$refs.tabItems.forEach(ref => {
        if (Object.prototype.hasOwnProperty.call(ref, "resetData")) {
          ref.resetData();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>

<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Tùy chọn sản phẩm</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <!-- Start: Product Type -->
      <div class="">
        <div class="font-weight-bold mb-2">Loại sản phẩm</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          v-model="selectedProductsTypes"
          :items="productTypes"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn loại sản phẩm"
        ></v-autocomplete>
      </div>
      <!-- End: Product Type -->
      <!-- Start: Brand -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thương hiệu</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          v-model="selectedBrands"
          :items="brands"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn thương hiệu"
        ></v-autocomplete>
      </div>
      <!-- End: Brand -->
      <!-- Start: Product source -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nguồn hàng</div>
        <v-radio-group
          v-model="selectedProductSource"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Chính hãng" :value="5"></v-radio>
          <v-radio label="Nhập khẩu" :value="6"></v-radio>
        </v-radio-group>
      </div>
      <!-- End: Product source -->
      <!-- Start: Product status -->
      <div class="mt-5">
        <!-- Product Status -->
        <div class="font-weight-bold mb-2">Tình trạng máy</div>
        <v-checkbox
          v-model="selectedProductStatus"
          class="mt-1"
          dense
          hide-details
          label="New sealed"
          :value="1"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedProductStatus"
          class="mt-0"
          dense
          hide-details
          label="New, Fullbox"
          :value="2"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedProductStatus"
          class="mt-0"
          dense
          hide-details
          label="New, Outlet"
          :value="3"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedProductStatus"
          class="mt-0"
          dense
          hide-details
          label="Used"
          :value="4"
        ></v-checkbox>
      </div>
      <!-- End: Product status -->
      <!-- Start: Stock status -->
      <div class="mt-5">
        <!-- Store Status -->
        <div class="font-weight-bold mb-2">Tình trạng kho</div>
        <v-checkbox
          v-model="selectedStoreStatus"
          class="mt-1"
          dense
          hide-details
          label="Có sẵn"
          :value="1"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStoreStatus"
          class="mt-1"
          dense
          hide-details
          label="Đang về"
          :value="2"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStoreStatus"
          class="mt-1"
          dense
          hide-details
          label="Chỉ bán online"
          :value="3"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedStoreStatus"
          class="mt-1"
          dense
          hide-details
          label="Liên hệ"
          :value="4"
        ></v-checkbox>
        <!--        <v-radio-group-->
        <!--          v-model="selectedStoreStatus"-->
        <!--          class="mt-0"-->
        <!--          dense-->
        <!--          hide-details-->
        <!--        >-->
        <!--          <v-radio label="Tất cả" value="all"></v-radio>-->
        <!--          <v-radio label="Có sẵn" :value="1"></v-radio>-->
        <!--          <v-radio label="Đang về" :value="2"></v-radio>-->
        <!--          <v-radio label="Chỉ bán online" :value="3"></v-radio>-->
        <!--          <v-radio label="Liên hệ" :value="4"></v-radio>-->
        <!--        </v-radio-group>-->
      </div>
      <!-- End: Stock status -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tình trạng đặt hàng</div>
        <v-checkbox
          v-model="selectedOrderedStatus"
          class="mt-1"
          dense
          hide-details
          label="Bị đặt"
          :value="1"
        >
        </v-checkbox>
        <v-checkbox
          v-model="selectedWaitingOrderedStatus"
          class="mt-1"
          dense
          hide-details
          label="Khách đặt chờ"
          :value="1"
        >
        </v-checkbox>
      </div>
      <!-- Start: Price -->
      <div class="mt-5">
        <!-- Store Status -->
        <div class="font-weight-bold mb-2">Giá bán</div>
        <tp-input-range-price
          :value="priceFilter"
          @change="updatePriceFilter($event)"
        ></tp-input-range-price>
      </div>
      <!-- End: Price -->
      <!-- Start: Select site -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">
          Hiển thị trên website
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
          <v-radio label="Bị ẩn" value="NONE"></v-radio>
        </v-radio-group>
      </div>
      <!-- End: Select site -->
      <!-- Start: Front display -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-radio-group
          v-model="selectedStatusDisplay"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio
            v-for="(item, index) in Object.values(PRODUCT_STATUS)"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </div>
      <!-- End: Front display -->
      <!-- Start: Sale status -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tình trạng kinh doanh</div>
        <v-radio-group
          v-model="selectedSaleStatus"
          class="mt-0"
          dense
          hide-details
        >
          <v-radio label="Đang kinh doanh" :value="1"></v-radio>
          <v-radio label="Ngừng kinh doanh" :value="0"></v-radio>
        </v-radio-group>
        <v-checkbox
          v-model="selectedNotSoldWarehouseStatus"
          class="mt-1"
          dense
          hide-details
          label="Đang trưng bày"
          :value="-13"
        >
        </v-checkbox>
      </div>
      <!-- End: Sale status -->
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

import { PRODUCT_STATUS } from "@/modules/Goods/constants";

export default {
  props: {
    brandsFilter: {
      type: Array
    },
    status: {
      type: String
    },
    notSoldWarehouseStatusFilter: {
      type: Array
    },
    orderedStatusFilter: {
      type: Number
    },
    priceFilter: {
      type: Object
    },
    productSourceFilter: {
      type: [Number, String]
    },
    productStatusFilter: {
      type: Array
    },
    productTypesFilter: {
      type: Array
    },
    saleStatusFilter: {
      type: [Number, String]
    },
    siteFilter: {
      type: String
    },
    storeStatusFilter: {
      type: Array
    },
    waitingOrderedStatusFilter: {
      type: Number
    }
  },

  data() {
    return { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES, PRODUCT_STATUS };
  },

  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },
    selectedBrands: {
      get() {
        return this.brandsFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "brandsFilter",
          value: val
        });
      }
    },
    selectedStatusDisplay: {
      get() {
        return this.status;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "status",
          value: val
        });
      }
    },
    selectedNotSoldWarehouseStatus: {
      get() {
        return this.notSoldWarehouseStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "notSoldWarehouseStatusFilter",
          value: val
        });
      }
    },
    selectedOrderedStatus: {
      get() {
        return this.orderedStatusFilter;
      },
      set(val) {
        return this.$emit("updateFilterProperty", {
          filterProperty: "orderedStatusFilter",
          value: val
        });
      }
    },
    selectedProductSource: {
      get() {
        return this.productSourceFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productSourceFilter",
          value: val
        });
      }
    },
    selectedProductStatus: {
      get() {
        return this.productStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productStatusFilter",
          value: val
        });
      }
    },
    selectedProductsTypes: {
      get() {
        return this.productTypesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productTypesFilter",
          value: val
        });
      }
    },
    selectedSaleStatus: {
      get() {
        return this.saleStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "saleStatusFilter",
          value: val
        });
      }
    },
    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    },
    selectedStoreStatus: {
      get() {
        return this.storeStatusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "storeStatusFilter",
          value: val
        });
      }
    },
    selectedWaitingOrderedStatus: {
      get() {
        return this.waitingOrderedStatusFilter;
      },
      set(val) {
        return this.$emit("updateFilterProperty", {
          filterProperty: "waitingOrderedStatusFilter",
          value: val
        });
      }
    }
  },
  created() {
    this.$store.dispatch("BRAND/getAllBrands");
    this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
  },

  methods: {
    updatePriceFilter(val) {
      if (val.from && val.to && parseInt(val.to) >= parseInt(val.from)) {
        this.$emit("updateFilterProperty", {
          filterProperty: "priceFilter",
          value: val
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"modalProductOption_tabSerials"}},[_c('div',{staticClass:"d_flex align_center mb-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v("Có "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.productOptionSerials.length))]),_vm._v(" serials")])]),_c('v-spacer'),_c('tp-btn-overflow',{attrs:{"items":_vm.computedBranches,"selected-value":_vm.computedBranches[_vm.selectedBranch].id,"btn-class":"rounded-lg white text-body-1 py-1 px-3 mr-4","dense":"","placeholder":"Chọn chi nhánh"},on:{"change":function($event){return _vm.filterSerialByBranch($event)}}}),_c('tp-btn-overflow',{attrs:{"items":_vm.statusList,"selected-value":_vm.statusList[_vm.selectedStatus].id,"btn-class":"rounded-lg white text-body-1 py-1 px-3","dense":"","placeholder":"Chọn trạng thái"},on:{"change":function($event){return _vm.filterSerialByStatus($event)}}})],1),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"datatable px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.productOptionSerials,"loading":_vm.productOptionStatusRequest === 'loading-getProductOptionSerials',"calculate-widths":"","disable-pagination":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có serial nào","no-results-text":"Không tìm thấy kết quả phù hợp"},scopedSlots:_vm._u([{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"copied-label font-weight-bold",attrs:{"color":"cyan lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(
                  item.serial_number,
                  'modalProductOption_tabSerials'
                )}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"pa-2 d-flex flex-wrap gap-2"},[_c('v-chip',{staticClass:"copied-label font-weight-bold",attrs:{"color":_vm.getSerialStatus(item.status).color,"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.getSerialStatus(item.status).text)+" ")]),(item.exchange_status)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"orange","outlined":"","small":""}},[_vm._v(" Đang chuyển ")]):_vm._e()],1)]}},{key:"item.order_code_pinned",fn:function(ref){
                var item = ref.item;
return [(item.order_code_pinned)?_c('button',{staticClass:"primary--text",on:{"click":function($event){return _vm.viewOrderDetail(item.order_id)}}},[_vm._v(" "+_vm._s(item.order_code_pinned)+" ")]):_c('span',[_vm._v("N/A")])]}}],null,true)})],1),_c('input',{attrs:{"id":"modalProductOptionSerial_copyInput","type":"text"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
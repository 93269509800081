<template>
  <div id="modalProductOption_tabSerials">
    <!-- Start: Top Action -->
    <div class="d_flex align_center mb-4">
      <div class="d-flex align-center">
        <span
          >Có
          <span class="font-weight-bold">{{
            productOptionSerials.length
          }}</span>
          serials</span
        >
      </div>
      <v-spacer></v-spacer>
      <tp-btn-overflow
        :items="computedBranches"
        :selected-value="computedBranches[selectedBranch].id"
        btn-class="rounded-lg white text-body-1 py-1 px-3 mr-4"
        dense
        placeholder="Chọn chi nhánh"
        @change="filterSerialByBranch($event)"
      ></tp-btn-overflow>
      <tp-btn-overflow
        :items="statusList"
        :selected-value="statusList[selectedStatus].id"
        btn-class="rounded-lg white text-body-1 py-1 px-3"
        dense
        placeholder="Chọn trạng thái"
        @change="filterSerialByStatus($event)"
      ></tp-btn-overflow>
    </div>
    <!-- End: Top Action -->
    <!-- Start: Data table-->
    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="productOptionSerials"
        :loading="
          productOptionStatusRequest === 'loading-getProductOptionSerials'
        "
        calculate-widths
        class="datatable px-3 py-2"
        disable-pagination
        hide-default-footer
        item-key="id"
        loading-text="Đang tải dữ liệu"
        no-data-text="Không có serial nào"
        no-results-text="Không tìm thấy kết quả phù hợp"
      >
        <template v-slot:[`item.serial_number`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="copied-label font-weight-bold"
                color="cyan lighten-4"
                label
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="
                  copyToClipboard(
                    item.serial_number,
                    'modalProductOption_tabSerials'
                  )
                "
              >
                {{ item.serial_number }}
              </v-chip>
            </template>
            <span>Sao chép</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="pa-2 d-flex flex-wrap gap-2">
            <v-chip
              :color="getSerialStatus(item.status).color"
              class="copied-label font-weight-bold"
              outlined
              small
            >
              {{ getSerialStatus(item.status).text }}
            </v-chip>

            <v-chip
              v-if="item.exchange_status"
              class="font-weight-bold"
              color="orange"
              outlined
              small
            >
              Đang chuyển
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.order_code_pinned`]="{ item }">
          <button
            v-if="item.order_code_pinned"
            class="primary--text"
            @click="viewOrderDetail(item.order_id)"
          >
            {{ item.order_code_pinned }}
          </button>

          <span v-else>N/A</span>
        </template>
      </v-data-table>
    </v-card>
    <!-- End: Data table-->
    <input id="modalProductOptionSerial_copyInput" type="text" />
  </div>
</template>

<script>
import { getSerialStatusByValue } from "../../../../../../utils/common";
import { LIST_STATUS_SERIAL } from "../../../../../../constants";

export default {
  data() {
    return {
      headers: [
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial_number"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Thời gian nhập",
          align: "start",
          sortable: false,
          value: "import_date"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "status"
        },
        {
          text: "Mã đơn đặt",
          align: "start",
          sortable: false,
          value: "order_code_pinned"
        }
      ],
      selectedBranch: 0,
      selectedStatus: 0,
      statusList: [
        { id: "all", text: "Tất cả trạng thái", color: null },
        ...LIST_STATUS_SERIAL
      ]
    };
  },
  computed: {
    computedBranches() {
      const res = this.branches.map(branch => {
        return {
          id: branch.id,
          text: branch.name
        };
      });

      res.unshift({
        id: "all",
        text: "Tất cả chi nhánh"
      });

      return res;
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    productOptionInfo() {
      return this.$store.getters["PRODUCT_OPTION/productOptionInfo"];
    },
    productOptionSerials() {
      return this.$store.getters["PRODUCT_OPTION/productOptionSerials"];
    },
    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    async filterSerialByBranch(val) {
      this.selectedBranch = val;
      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionSerials", {
        id: this.productOptionInfo.id,
        filters: {
          branch:
            this.selectedBranch === 0
              ? null
              : this.computedBranches[this.selectedBranch].id,
          status:
            this.selectedStatus === 0
              ? null
              : this.statusList[this.selectedStatus].id
        }
      });
    },

    async filterSerialByStatus(val) {
      this.selectedStatus = val;
      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionSerials", {
        id: this.productOptionInfo.id,
        filters: {
          branch:
            this.selectedBranch === 0
              ? null
              : this.computedBranches[this.selectedBranch].id,
          status:
            this.selectedStatus === 0
              ? null
              : this.statusList[this.selectedStatus].id
        }
      });
    },

    async viewOrderDetail(id) {
      await this.$store.dispatch("ORDER/getOrderById", id);

      this.$modal.show({
        name: "modal-order"
      });

      // Get order payments
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", id);

      // Get order participant
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: id
        }
      );

      // Get order online payments
      this.$store.dispatch("ORDER/getOrderOnlinePayments", id);
    },

    getSerialStatus(value) {
      return getSerialStatusByValue(value);
    },

    resetData() {
      this.selectedBranch = 0;
      this.selectedStatus = 0;
    }
  }
};
</script>

<style scoped>
.gap-2 {
  gap: 8px;
}
</style>

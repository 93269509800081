<template>
  <v-menu v-model="menu" :close-on-content-click="true" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-4">
      <div>
        <div
          v-for="item in urlArray"
          :key="item.site"
          class="d-flex justify-space-between align-center"
        >
          <div class="mr-2 font-weight-bold text-body-1">{{ item.site }}</div>

          <div>
            <v-btn
              color="primary"
              depressed
              :href="item.url"
              small
              target="_blank"
              text
              >Truy cập</v-btn
            >
            <v-btn
              color="primary"
              depressed
              small
              text
              @click="
                $modal.show({ name: 'modal-utm-builder', payload: item.url })
              "
              >UTM Builder</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    urls: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      menu: false
    };
  },

  computed: {
    urlArray() {
      return this.urls
        ? Object.entries(this.urls).map(([site, url]) => ({
            site: site.charAt(0).toUpperCase() + site.slice(1).toLowerCase(),
            url
          }))
        : [];
    }
  }
};
</script>

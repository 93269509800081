<template>
  <tp-modal name="modal-product-options-change-front-display" width="400px">
    <v-card slot-scope="props">
      <v-card-title>Ẩn/hiện sản phẩm trên website</v-card-title>
      <v-card-text class="text-body-1">
        Tùy chỉnh ẩn/hiện trên website các tùy chọn sản phẩm (SKU) đã chọn
        (<strong>{{ props.payload.selectedLength }} SKU</strong>)
      </v-card-text>
      <div class="mx-6">
        <v-switch
          v-model="frontDisplay"
          class="mt-0 pt-0"
          :false-value="0"
          flat
          :label="frontDisplay === 1 ? 'Hiện trên website' : 'Ẩn trên website'"
          color="success"
          :true-value="1"
          hide-details
        ></v-switch>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal()">
          Hủy
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="updateFrontDisplay(props.payload.selectedItems)"
        >
          Lưu
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      frontDisplay: 1
    };
  },
  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-product-options-change-front-display"
      });
    },
    async updateFrontDisplay(arr) {
      await this.$store.dispatch("PRODUCT_OPTION/updateFrontDisplay", {
        options: arr.map(item => {
          return {
            option_id: item.id,
            front_display: this.frontDisplay
          };
        })
      });
      // Alert
      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã lưu cập nhật"
        }
      });
      // Close modal
      this.closeModal();
    }
  }
};
</script>
